






































































































































import Vue from "vue";
import userManager from "@/services/userManager";
import { roles } from "@/constants/users";
import configProvider from "@common/services/configProvider";

export default Vue.extend({
  data() {
    return {
      self: this,
      roles,
      mini: true,
      logoUrl: configProvider.get("LOGO_URL"),
      logoText: configProvider.get("LOGO_TEXT"),
      items: [
        {
          title: "Users",
          icon: "mdi-account-circle",
          to: "/app/users",
          condition() {
            return userManager.checkRole(["admin"]);
          },
        },
        {
          title: "Stores",
          icon: "mdi-store",
          to: "/app/stores",
          condition() {
            return userManager.checkRole(["admin"]);
          },
        },
        {
          title: "Shipping Vendors",
          icon: "mdi-truck",
          to: "/app/shipping_vendors",
          condition() {
            return userManager.checkRole(["admin"]);
          },
        },
        {
          title: "Products",
          icon: "mdi-cube",
          to: "/app/products",
        },
        {
          title: "Product Variants",
          icon: "fas fa-cubes",
          to: "/app/product_variants",
        },
        {
          title: "Product Imports",
          icon: "mdi-application-import",
          to: "/app/product_imports",
        },
        {
          title: "Orders",
          icon: "mdi-shopping",
          to: "/app/orders",
        },
        {
          title: "Fulfillment",
          icon: "mdi-printer",
          to: "/app/fulfillment",
        },
        {
          title: "Action Logs",
          icon: "mdi-clipboard-text-clock",
          to: "/app/action_logs",
          condition() {
            return userManager.checkRole(["admin"]);
          },
        },
        {
          title: "Setting",
          icon: "mdi-cog-outline",
          to: "/app/setting",
          condition() {
            return userManager.checkRole(["admin"]);
          },
        },
        {
          title: "Change Password",
          icon: "mdi-form-textbox-password",
          to: "/app/change_password",
        },
      ],
      userInfo: {},
    };
  },
  methods: {
    async logout() {
      userManager.deleteAccessToken();
      this.$router.push("/login");
    },
  },
  created() {
    this.userInfo = userManager.getUserInfo();
  },
});
